import { useEffect, useState } from "react"

// TODO: Add tests and import spacing from typeography.js
export const getColumns = width => {
  const columns = [[]]
  const minWidth = 320
  const margin = width > 768 ? 64 : 32

  while (width - margin * 2 > minWidth * (columns.length + 1)) {
    columns.push([])
  }

  return columns
}

// TODO: Add tests
export const useColumns = ({ items, width }) => {
  const [columns, setColumns] = useState([])

  useEffect(() => {
    if (width) {
      setColumns(
        items.reduce(
          ([columns, columnIndex], item) => {
            columns[columnIndex++].push(item)

            if (columnIndex === columns.length) {
              columnIndex = 0
            }

            return [columns, columnIndex]
          },
          [getColumns(width), 0]
        )[0]
      )
    }
  }, [items, width])

  return columns
}
