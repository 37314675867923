import React from "react"
import Grid from "../grid/grid"
import Post from "./post"

const PostListContent = ({ posts }) => {
  return (
    <Grid items={posts.map(post => ({ ...post, id: post.path || post.id }))}>
      {post => <Post key={post.path} {...post} />}
    </Grid>
  )
}

export default PostListContent
